export const IMAGES = [
  {
    id: 0,
    src: "assets/1.jpg",
  },
  {
    id: 1,
    src: "assets/2.jpg",
  },
  {
    id: 2,
    src: "assets/3.jpg",
  },
  {
    id: 3,
    src: "assets/4.png",
  },
  {
    id: 4,
    src: "assets/5.png",
  },
  {
    id: 5,
    src: "assets/6.jpeg",
  },
  {
    id: 6,
    src: "assets/7.jpeg",
  },
  {
    id: 7,
    src: "assets/8.jpeg",
  },
  {
    id: 8,
    src: "assets/9.jpeg",
  },
  {
    id: 9,
    src: "assets/10.jpeg",
  },
  {
    id: 10,
    src: "assets/11.png",
  },
  {
    id: 11,
    src: "assets/12.jpeg",
  },
  {
    id: 12,
    src: "assets/13.jpeg",
  },
  {
    id: 13,
    src: "assets/14.jpeg",
  },
  {
    id: 14,
    src: "assets/15.jpeg",
  },
];
